<template>
	<div class="history" v-if="!loading">
		<v-row>
			<v-col cols="12">
				<v-card class="" outlined>
					<v-card-title class="text-h4 ">
						History Orders
					</v-card-title>

					<v-card-text class="mt-2 ">
						<v-data-table
							:headers="headers"
							:items="orders"
							item-key="name"
							class="text-body-1"
							:items-per-page="10"
							:footer-props="{'items-per-page-options': [10,20,50,-1]}"
							mobile-breakpoint="0"
						>
							<template v-slot:item.name="{ item }">
								<router-link
									class="blue--text font-weight-500"
									:to="{name: 'OrderHistoryShow', params:{number: item.name_url}}"
								>
									{{ item.name }}
								</router-link>
							</template>
							<template v-slot:item.total_payment_client="{ item }">
								{{ item.total_payment_client / 100 | euro }}
							</template>
							<template v-slot:item.total_payment_retail="{ item }">
								{{ item.total_payment_retail / 100 | euro }}
							</template>
							<template v-slot:item.created_at="{ item }">
								{{ $dayjs.unix(item.created_at).format('YYYY-MM-DD HH:mm:ss') }}
							</template>
							<template v-slot:item.actions="{ item }">
								<v-btn
									color="green"
									class="white--text"
									:to="{name: 'OrderHistoryShow', params:{number: item.name_url}}"
									:id="'history-show-'+ item.name_url"
								>
									Show
								</v-btn>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

	</div>
</template>
<script>

export default {
	name: 'OrderHistoryIndex',
	components: {},
	computed: {
		orders() {
			return this.$store.getters['ORDER_HISTORY_STORE/ORDERS'];
		},

	},
	data: () => ({
		loading: false,
		search: '',

		headers: [
			{
				text: 'Number', value: 'name', sortable: true, align: 'left'
			},
			{
				text: 'Wholesale value of your order',
				value: 'total_payment_client',
				sortable: true,
				align: 'right',
				width: 150
			},
			{
				text: 'Retail value of your order',
				value: 'total_payment_retail',
				sortable: true,
				width: 150,
				align: 'right'
			},
			/*{
				text: 'Discount level', value: 'your', sortable: true, width: 150, align: 'right',
			},*/
			{
				text: 'Created at', value: 'created_at', sortable: true, width: 140, align: 'right',
			},
			{
				text: 'Status', value: 'status.name_en', sortable: true, width: 120, align: 'right',
			},
			{
				text: '', value: 'actions', sortable: false, width: 120, align: 'right',
			},
		],
	}),
	methods: {},
	created() {
		this.loading = true;

		this.$store.dispatch('ORDER_HISTORY_STORE/all')
			.then(() => {
				this.loading = false
			})
			.catch(err => {
				console.error(err)
				this.loading = false
			});
	}
};
</script>
